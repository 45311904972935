<template>
  <div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
      ref="dlgImport"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              v-if="!busy"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">{{ $t("screen_importation") }}</h4>
          </div>
          <div class="modal-body">
            <div class="">
              <label for="">{{ $t("destination") }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  {{ $tc("screen") }}
                </div>
                <select
                  class="form-control"
                  v-model="screen_id"
                  :disabled="busy"
                >
                  <option value="">{{ $tc("new", 2) }}</option>
                  <option
                    :value="screen.id"
                    :key="screen.id"
                    v-for="screen in parsedScreens"
                  >
                    {{ screen.name }}
                  </option>
                </select>
                <div class="input-group-btn">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :disabled="busy"
                  >
                    V{{ version }} <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu" style="">
                    <li>
                      <a href="#" @click="version = 0">V0</a>
                      <a href="#" @click="version = 1">V1</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="alert alert-danger" v-if="screen_id">
              {{ $t("this_will_reset_all_of_your_unpushed_changes") }}
            </div>
          </div>
          <div class="modal-footer">
            <template v-if="busy">
              <span class="span-info">
                <i class="fa fa-refresh fa-spin"></i>
                {{ $t("please_wait_no_timeout") }}
              </span>
            </template>
            <template v-else>
              <span
                v-if="
                  reuploadImageNames.length == 0 && errorImageNames.length == 0
                "
                class="span-info"
              >
                <i class="fa fa-check text-green"></i>
                {{ $t("no_images_to_import") }}
              </span>

              <span
                v-if="
                  reuploadImageNames.length > 0 || errorImageNames.length > 0
                "
                class="span-info"
              >
                <span v-if="errorImageNames.length > 0" class="block">
                  <i class="fa fa-ban text-red"></i>
                  {{
                    $t("there_are_n_images_with_error", {
                      count: errorImageNames.length
                    })
                  }}<br />
                </span>
                <span
                  v-for="filename in errorImageNames"
                  :key="'img_' + filename + '_err'"
                >
                  <small class="label bg-red">
                    <i class="fa fa-ban"></i>&nbsp;
                    {{ filename }}
                  </small>
                  &nbsp;
                </span>

                <span v-if="reuploadImageNames.length > 0" class="block">
                  <i class="fa fa-info-circle text-blue"></i>
                  {{
                    $t("there_are_n_images_to_import", {
                      count: reuploadImageNames.length
                    })
                  }}<br />
                </span>
                <span
                  v-for="filename in reuploadImageNames"
                  :key="'img_' + filename"
                >
                  <small class="label bg-gray">
                    <i class="fa fa-file-image-o"></i>&nbsp;
                    {{ filename }}
                  </small>
                  &nbsp;
                </span>
              </span>

              <template v-if="errorImageNames.length == 0">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop.prevent="onImport"
                >
                  <i class="fa fa-upload"></i>
                  {{ $t("import") }}
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop.prevent="onSave"
                >
                  <i class="fa fa-warning"></i>
                  {{ $t("to_continue") }}
                </button>

                <button
                  type="button"
                  class="btn btn-danger"
                  v-on:click.stop.prevent="close"
                >
                  <i class="fa fa-ban"></i>
                  {{ $t("abort") }}
                </button>
              </template>
            </template>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </div>
</template>

<script>
import {nextId} from "@/services/dashboard";
import MixinAlert from "@/project/mixin-alert.js";
import Panels from "@/assets/dashboard/panels.json";
import ImageService from "@/services/image.js";
import ScreenService from "@/services/screen.js";
import * as JSZip from "jszip";

export default {
  name: "ImportForm",
  mixins: [MixinAlert],
  props: {
    screenId: {
      type: [String, Number],
      default: "",
      required: false
    }
  },
  data() {
    return {
      isFileSelected: false,
      fileSelected: null,
      json_content: "",
      template: null,
      images_to_reupload: [],
      images_error: [],
      screen_id: "",
      version: 0,
      busy: false
    };
  },
  computed: {
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    parsedScreens() {
      let lst = JSON.parse(JSON.stringify(this.screens));
      return lst
        .filter((i) => {
          return !i.public;
        })
        .map((i) => {
          return {
            id: i.id,
            name: i.id ? i.name + " [#" + i.id + "]" : i.name
          };
        })
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        });
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    draftScreenId() {
      return this?.draft?.screenId || "";
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    autoLibraryImageLibrary() {
      return (this.$store.getters["synoptic/imagesLibraries"] || []).find(
        (item) => item.name == this.auto_images_library_name
      );
    },
    autoLibraryImages() {
      return (this.$store.getters["synoptic/images"] || []).filter(
        (item) => item.images_library_id == this.autoLibraryImageLibrary?.id
      );
    },
    autoLibraryImageNames() {
      return this.autoLibraryImages.map((image) => image.name);
    },
    reuploadImageNames() {
      if (this.images_to_reupload.length == 0) return [];
      return this.images_to_reupload
        .filter(
          (url) =>
            this.images_error.indexOf(url) < 0 &&
            this.images_error.indexOf(decodeURIComponent(url)) < 0 &&
            (this.autoLibraryImageNames.indexOf(this.getImageName(url)) < 0 ||
              this.autoLibraryImageNames.indexOf(
                this.getImageName(decodeURIComponent(url))
              ) < 0)
        )
        .map((url) => this.getImageName(url));
    },
    errorImageNames() {
      if (this.images_error.length == 0) return [];
      return this.images_error.map((url) => this.getImageName(url));
    }
  },
  methods: {
    isInvalidImageFile(file) {
      // Requisição de uma imagem retornou um formato inválido
      return (
        !file || file?.type == "application/xml" || file?.type == "text/html"
      );
    },
    fetchFile(url) {
      // Obtém um arquivo de uma URL
      return new Promise((resolve) =>
        fetch(url)
          .then((response) => {
            if (response.status == 200) {
              return response.blob();
            } else {
              this.images_error.push(url);
              resolve(null);
            }
          })
          .then((blob) => {
            if (blob) {
              resolve(
                new File([blob], this.getImageName(url), {
                  type: blob.type || "image/jpeg"
                })
              );
            } else {
              resolve(null);
            }
          })
      );
    },
    getImageName(url) {
      // Obtém o nome do arquivo de uma URL
      const parts = url
        .replace("http://", "")
        .replace("https://", "")
        .split("/");
      const index = parts.length > 1 ? parts.length - 1 : 0;
      return parts[index];
    },
    getMatchingImage(url) {
      // Obtém o arquivo correspondente à URL informada no Contrato atual
      return new Promise((resolve, reject) => {
        // Verifica se já existe a imagem em "Imagens Importadas", conforme URL
        const image_name = this.getImageName(url);
        const image = this.autoLibraryImages.find(
          (item) => item.name == image_name
        );

        // Se já existe, retorna o objeto
        if (image && image?.id) resolve(image);
        // Se ainda não existe, faz upload
        else
          this.fetchFile(url).then((file) => {
            if (!file) {
              reject(null);
              return;
            }
            this.image_service
              .save({
                description: "",
                images_library_id: this.autoLibraryImageLibrary?.id,
                path: file
              })
              .then((result) => setTimeout(() => resolve(result)), 2000);
          });
      });
    },
    getImagesToReload(content) {
      // Obtém as imagens encontradas no arquivo que NÃO estão neste Contrato
      const contractUuid = this.contract?.uuid || "";
      const regex = /"http.*:\/\/.*\/media\/.*"/g;
      let results = [...content.matchAll(regex)];
      results = results
        // Faz uma limpeza na URL, removendo as aspas e o parâmetro de cache
        .map((item) => item[0].replaceAll('"', "").split("?")[0])
        .filter(
          (url) =>
            // Ignora arquivos JSON referenciados
            !url.endsWith(".json") &&
            // Remove os travessões para ver se a mídia não está no Contrato
            // atual procurando pelo UUID na URL obtida do arquivo
            url.replaceAll("-", "").indexOf("/" + contractUuid + "/") < 0
        );
      // Remove duplicados
      return [...new Set(results)];
    },
    getImagesToCheck(content) {
      // Obtém as imagens encontradas no arquivo
      const regex = /"http.*:\/\/.*\/media\/.*"/g;
      let results = [...content.matchAll(regex)];
      results = results
        // Faz uma limpeza na URL, removendo as aspas e o parâmetro de cache
        .map((item) => item[0].replaceAll('"', "").split("?")[0])
        .filter(
          (url) =>
            // Ignora arquivos JSON referenciados
            !url.endsWith(".json")
        );
      // Remove duplicados
      if (!results.length) return [];
      return [...new Set(results)];
    },
    getOrReuploadImage(url) {
      // Obtém a imagem de "Imagens Importadas" ou faz novo upload pela URL
      return new Promise((resolve) => {
        this.getMatchingImage(url).then((result) => {
          // Remove da lista de pendentes
          this.images_to_reupload = this.images_to_reupload.filter(
            (filter_url) => filter_url != url
          );
          // Altera o conteúdo do JSON, substituindo a URL original pela nova
          this.json_content = this.json_content.replaceAll(url, result.path);
          // Retorna a solução
          resolve(true);
        });
      });
      //
    },
    async onImport() {
      if (!this.fileSelected) return;

      // Se for arquivo compactado
      if (this.fileSelected.name.endsWith(".hps")) {
        return this.onUploadZip();
        // Se for JSON com imagens a recarregar
      } else if (
        this.fileSelected.name.endsWith(".json") &&
        this.images_to_reupload.length > 0
      ) {
        return this.onSaveReloadImages();
        // Se for JSON somente
      } else {
        return this.onSave();
      }
    },
    async onSaveReloadImages() {
      // Realiza a criação do draft da tela importada quando há
      // imagens para recarregar
      // Percorre todas imagens encontradas que não fazem parte deste Contrato
      this.busy = true;
      const promise_list = this.images_to_reupload
        .slice(0)
        .reverse()
        .map((url) => this.getOrReuploadImage(url));
      Promise.all(promise_list).then(() => {
        // Caso tenha encontrado algum erro nas imagens, pode finalizar a
        // importação apenas se o usuário confirmar
        if (this.images_error.length > 0) {
          this.busy = false;
          // Caso contrário, segue a importação automaticamente
        } else {
          if (this.json_content) {
            try {
              this.template = JSON.parse(this.json_content);
              this.busy = false;
            } catch (e) {
              this.alert = {
                title: this.$t("error"),
                text: this.$t("error_invalid_json_file"),
                type: "error"
              };
              let self = this;
              this.showAlert(function () {
                $(self.$refs.dlgImport).modal("hide");
                self.$emit("close");
              });
              return;
            }
          }
          this.onSave();
        }
      });
    },
    onSave() {
      // Realiza a criação do draft da tela importada quando não há
      // imagens para recarregar
      if (this.template) {
        this.upgrade();
        if (this.screen_id) {
          let screen = JSON.parse(
            JSON.stringify(this.screens.find((i) => i.id == this.screen_id))
          );
          if (screen) {
            this.$store.commit("dashboard/SAVE_DRAFT", {
              screenId: this.screen_id,
              template: this.template
            });
            this.$store.dispatch("dashboard/initDraft", this.screen_id);
            this.$store.dispatch("synoptic/fetchImages");
          }
          this.close();
        } else {
          this.$store
            .dispatch("dashboard/create", this.template)
            .then((draft) => {
              this.$store.dispatch("dashboard/setParent", {
                dbKey: "tree_screen",
                id: draft.screenId
              });
              this.$store.dispatch("synoptic/fetchImages");
              this.close();
            });
        }
      }
    },
    close() {
      if (this.isFileSelected) {
        $(this.$refs.dlgImport).modal("hide");
      } else {
        this.$emit("close");
      }
    },
    upgrade() {
      // convert to the new render version (if needed)
      if (this.version && this.template && !this.template.render_version) {
        this.template.render_version = this.version;
        this.template.panels.forEach((panel) => {
          let tpl =
            Panels.find((i) => i.template.template == panel.template) || null;
          if (tpl) {
            panel.heightProportion = tpl.template.heightProportion;
            panel.style = JSON.parse(JSON.stringify(tpl.template.style));
          }
        });
      }
    },
    onUploadZip() {
      // Realiza o upload do arquivo selecionado
      let form_data = new FormData();
      form_data.append("contract_id", this.contract.id);
      form_data.append("images_library_name", this.auto_images_library_name);
      form_data.append("file", this.fileSelected);

      this.busy = true;

      // Upload do arquivo de Tela compactado
      this.screen_service.backupImport(form_data).then((response) => {
        // Tratamento de erro
        if (response.status != 200) {
          let message = "";
          if (response.status == 413 || response.status == 0) {
            message = this.$t("error_file_too_large");
          } else {
            message =
              (response?.body?.detail ?? response.bodyText) ||
              this.$t("unknown_error");
          }
          this.alert = {
            title: this.$t("error"),
            text: message,
            type: "error"
          };
          let self = this;
          this.showAlert(function () {
            $(self.$refs.dlgImport).modal("hide");
            self.$emit("close");
          });
          return;
        }

        // JSON obtido com sucesso
        this.json_content = "";
        try {
          this.template = JSON.parse(response.bodyText);
        } catch (e) {
          this.alert = {
            title: this.$t("error"),
            text: this.$t("error_invalid_json_file"),
            type: "error"
          };
          let self = this;
          this.showAlert(function () {
            $(self.$refs.dlgImport).modal("hide");
            self.$emit("close");
          });
          return;
        }
        delete this.template.origin;
        delete this.template.draft;
        this.version = this.template.render_version ? 1 : 0;
        this.onSave();
      });
    },
    readFile(file) {
      let self = this;
      let promise_list = [];

      // Verifica o arquivo selecionado para importar
      let reader = new FileReader();

      $(this.$refs.dlgImport).modal("show");

      reader.onload = (evt) => {
        try {
          this.busy = true;
          this.fileSelected = file;

          if (!this.autoLibraryImageLibrary?.id) {
            promise_list.push(
              new Promise((resolve, reject) => {
                self.$store.dispatch("synoptic/createImagesLibrary", {
                  contract_id: self.contract.id,
                  name: self.auto_images_library_name,
                  public: false
                })
                .then(() => resolve(true))
                .catch(() => reject(null));
              })
            );
          }

          // Arquivos HPS / ZIP
          if (file.name.endsWith(".hps")) {
            promise_list.push(
              new Promise((resolve, reject) => {
                const zipper = new JSZip();

                zipper.loadAsync(self.fileSelected)
                  .then(function (zip) {
                    self.images_to_reupload = Object.keys(zip.files).filter(
                      (file_name) => !file_name.endsWith(".json")
                    );
                    resolve(true)
                  })
                  .catch(() => reject(null));
              })
            );

          // Arquivos JSON
          } else {
            this.json_content = evt.target.result || "";
            try {
              this.template = JSON.parse(this.json_content);
            } catch (e) {
              this.alert = {
                title: this.$t("error"),
                text: this.$t("error_invalid_json_file"),
                type: "error"
              };
              this.showAlert(function () {
                $(self.$refs.dlgImport).modal("hide");
                self.$emit("close");
              });
              return;
            }
            delete this.template.origin;
            delete this.template.draft;
            this.version = this.template.render_version ? 1 : 0;

            // Valida se existem imagens do mesmo Contrato inacessíveis
            promise_list.concat(
              this.getImagesToCheck(this.json_content)
              .slice(0)
              .reverse()
              .map((url) => this.fetchFile(url))
            );

            // Obtém as imagens que são de outro Contrato
            this.images_to_reupload = this.getImagesToReload(this.json_content);
          }

          // Executa todas as ações/promises previstas, na ordem de solicitação
          Promise.all(promise_list).then(() => (this.busy = false));

        } catch (e) {
          console.log(e);
          this.close();
        }
      };
      reader.onerror = () => {
        this.close();
      };
      if (file.name.endsWith(".hps")) {
        reader.readAsArrayBuffer(file, "UTF-8");
      } else if (file.name.endsWith(".json")) {
        reader.readAsText(file, "UTF-8");
      }
    },
    onFileSelected() {
      return new Promise((resolve, reject) => {
        // create input file
        const el = document.createElement("input");
        el.id = +new Date();
        el.style.display = "none";
        el.setAttribute("type", "file");
        el.setAttribute("accept", ".json,.hps"); // HPS / ZIP
        el.addEventListener(
          "change",
          () => {
            this.isFileSelected = true;
            if (el?.files?.length == 0) {
              reject();
            } else {
              resolve(el.files[0]);
            }
            document.body.removeChild(document.getElementById(el.id));
          },
          {once: true}
        );
        window.addEventListener(
          "focus",
          () => {
            setTimeout(() => {
              if (!this.isFileSelected && document.getElementById(el.id)) {
                reject();
                document.body.removeChild(document.getElementById(el.id));
              }
            }, 300);
          },
          {once: true}
        );
        document.body.appendChild(el);
        el.click();
      });
    }
  },
  mounted() {
    this.image_service = new ImageService();
    this.screen_service = new ScreenService();
    this.auto_images_library_name = this.$t("auto_images_library_name");

    let nextScreenId = nextId();
    try {
      this.onFileSelected().then(
        (file) => {
          this.busy = true;
          this.readFile(file);
        },
        () => {
          this.close();
        }
      );
    } catch (e) {
      this.close();
    }
    $(this.$refs.dlgImport).on("hide.bs.modal", () => {
      this.$emit(
        "close",
        nextScreenId != nextId() ? nextScreenId : this.screen_id
      );
    });
  }
};
</script>

<style scoped>
.input-group-btn > .dropdown-toggle {
  background-color: transparent;
  display: block;
  border-color: lightgray;
}

.input-group-btn > .dropdown-toggle:focus {
  outline-color: transparent;
}

.input-group-btn > .dropdown-menu {
  min-width: 0;
  margin-left: -8px;
}

.block {
  display: block;
}

.span-info {
  text-align: left;
  float: left;
  max-width: 60%;
}
</style>
